import { css } from "aphrodite";
import { List } from "immutable";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect } from "react";

import TimelineProvider from "components/Analytics/TimelineProvider";
import LoadMoreButton from "components/Buttons/LoadMoreButton";
import StandardButton from "components/Buttons/StandardButton";

import TimelineActivitySelector from "./TimelineActivitySelector";
import TimelineAggregateSelector from "./TimelineAggregateSelector";
import TimelineSkeleton from "./TimelineSkeleton";
import useTimeline from "./useTimeline";

import generateTransition from "utils/generateTransition";

import { useLocalStorage } from "hooks/useLocalStorage";
import useMemo from "hooks/useLoggedMemo";
import { useSmoothScroll } from "hooks/useSmoothScroll";
import { useStyles } from "hooks/useStyles";
import useTimelineAction from "hooks/useTimelineAction";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  newActivityNotice: {
    ...gStyles.fontBold,
    background: colours.primary,
    padding: "0 1rem",
    borderRadius: 6,
    marginBottom: 0,
    border: "none",
    color: "#fff",
    textAlign: "center",
    cursor: "pointer",
    maxHeight: 0,
    fontSize: "0.75rem",
    minWidth: 250,
    margin: "0 auto",
    transition: generateTransition({
      targets: ["margin", "padding", "maxHeight"],
      speed: "250ms",
      easing: "cubic-bezier(0.465, 0.183, 0.153, 0.946)",
      delay: "0s",
    }),
  },
  newActivityNoticeActive: {
    maxHeight: "3rem",
    marginBottom: "1rem",
    padding: "0.5rem 1rem",
  },
  timeline: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "136rem",
  },
  aggGroup: {
    width: "100%",
  },
  nothingMoreContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2rem auto 5rem",
  },
  nothingMoreMessage: {
    ...gStyles.avalonBold,
    color: colours.bodyText,
    fontSize: "17px",
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  nothingMoreButton: {
    width: "8rem",
  },
  nonInfiniteWrapper: {
    width: "100%",
    overflow: "hidden",
  },
};

const RELOAD_MIN_TIMEOUT = 30000; // one minute

const Timeline = memo((props) => {
  const {
    aggregated,
    timelineKey,
    timelinePage,
    renderNothingMoreToLoad: passedRenderNothingMoreToLoad,
    myFeed,
    pageType,
    ...rest
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");
  const timeline = useTimeline(timelineKey);
  const { scrollToTop } = useSmoothScroll({ duration: 500, align: "top" });
  const loading = timeline && timeline.get("loading");

  const {
    actions: { loadTimeline, loadMoreActivities },
    timelineModuleLoaded,
  } = useTimelineAction();

  const backgroundLoadedActivities = timeline.get(
    "backgroundActivities",
    List()
  );

  const [lastReload, setLastReload] = useLocalStorage(
    "podchaser:last_timeline_reload"
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setLastReload(Date.now()), []); // only on mount

  const showBackgroundActivitesButton = useMemo(
    () =>
      backgroundLoadedActivities.size > 0 &&
      Date.now() - lastReload > RELOAD_MIN_TIMEOUT,
    [lastReload, backgroundLoadedActivities.size]
  );

  const reloadTimeline = useCallback(() => {
    setLastReload(Date.now());

    if (timelineModuleLoaded) {
      loadTimeline({ timelineKey });
    }
  }, [loadTimeline, timelineKey, setLastReload, timelineModuleLoaded]);

  const handleScroll = useCallback(() => scrollToTop(), [scrollToTop]);

  const handleLoadMoreActivities = useCallback(() => {
    if (!loading && timelineModuleLoaded) {
      loadMoreActivities({ timelineKey });
    }
  }, [loadMoreActivities, loading, timelineKey, timelineModuleLoaded]);

  const renderedActivities = useCallback(() => {
    if (!timeline.get("activities")) {
      return <TimelineSkeleton />;
    }

    if (loading) {
      return <TimelineSkeleton />;
    }

    return (
      <div className={css(styles.nonInfiniteWrapper)}>
        {timeline.get("activities").map((activity) => {
          if (aggregated) {
            return (
              <TimelineAggregateSelector
                group={activity}
                key={activity.get("id")}
                {...rest}
              />
            );
          }
          return (
            <TimelineActivitySelector
              activity={activity}
              key={activity.get("id")}
              {...rest}
              mobile={mobile}
            />
          );
        })}
      </div>
    );
  }, [timeline, loading, aggregated, styles.nonInfiniteWrapper, rest, mobile]);

  if (timeline.get("error")) {
    return <div>{timeline.get("error")}</div>;
  }

  const renderNothingMoreToLoad = () => {
    if (passedRenderNothingMoreToLoad) {
      return passedRenderNothingMoreToLoad();
    }

    return (
      <div className={css(styles.nothingMoreContainer)}>
        <div className={css(styles.nothingMoreMessage)}>
          Nothing More To Show
        </div>
        <div className={css(styles.nothingMoreButton)}>
          <StandardButton
            onClick={handleScroll}
            variation="white"
            label="Back to the top"
            flat
          />
        </div>
      </div>
    );
  };

  return (
    <TimelineProvider
      myFeed={myFeed}
      feedID={timelineKey}
      timelinePage={timelinePage}
      pageType={pageType}
    >
      {showBackgroundActivitesButton && (
        <button
          onClick={reloadTimeline}
          className={css(
            styles.newActivityNotice,
            backgroundLoadedActivities.size > 0 &&
              styles.newActivityNoticeActive
          )}
        >
          Load{" "}
          {`${
            backgroundLoadedActivities.size > 1
              ? backgroundLoadedActivities.size
              : ""
          } new ${pluralize("activity", backgroundLoadedActivities.size)}`}
        </button>
      )}
      <div className={css(styles.timeline)}>{renderedActivities()}</div>
      {!loading && !timeline.get("hasMore") ? (
        renderNothingMoreToLoad()
      ) : (
        <LoadMoreButton onClick={handleLoadMoreActivities} loading={loading} />
      )}
    </TimelineProvider>
  );
});

Timeline.propTypes = {
  timelineKey: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  aggregated: PropTypes.bool,
  timelinePage: PropTypes.string,
  renderNothingMoreToLoad: PropTypes.func,
  myFeed: PropTypes.bool,
};

Timeline.defaultProps = {
  aggregated: false,
  timelinePage: "Unknown",
  renderNothingMoreToLoad: null,
  myFeed: false,
};

export default Timeline;
